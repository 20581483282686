import {
  NO_INFO,
  WATER_AND_SEWER,
  ELECTRICITY,
  GAS,
  TRASH,
  INTERNET_CABLE,
  HEATING_AIR_CONDITIONING,
  IN_UNIT_WASHER_DRYER,
  DISHWASHER,
  MICROWAVE,
  STAINLESS_STEEL_APPLIANCES,
  HARDWOOD_FLOORING,
  INCLUDED_UTILITIES,
  INTERIOR_AMENITIES,
  OUTDOOR_AMENITIES,
  COMMUNITY_AMENITIES,
  CENTRAL_AC_HEATING,
  WALK_IN_CLOSETS,
  HIGH_SPEED_INTERNET,
  FIREPLACE,
  PATIO,
  BBQ_GRILLS_OR_PICNIC_AREA,
  ROOFTOP_DECK,
  PARKING_SPACE,
  GYM,
  SWIMMING_POOL,
  COMMUNITY_CLUBHOUSE,
  PLAYGROUND_OR_DOG_PARK,
  LAUNDRY_FACILITIES,
  PARKING_OR_GARAGE,
  SECURITY_OR_CONTROLLED_ACCESS,
  EN,
  UNAVAILABLE,
  PETS,
  ALLOWED,
  MONTH_PET_FEES,
  NO_LIMIT,
  PET_DEPOSIT,
  SMALL_WEIGHT,
  MEDIUM_WEIGHT,
  LARGE_WEIGHT,
  ALL_ALLOWED,
} from '~/assets/strings';
import { Presenter } from '~/framework/Presenter';
import { currency } from '~/global-contexts/utils/number';
import { useLocalization } from '~/hooks/useLocalization';
import { User } from '~/state/mainAppState';
import { PresentCompanyUnitListingDetail, TenantHydratedUnitListingDto, Feature } from '~/types/Unit';
import { capitalizeFirstLetter } from '~/utils/tools';
const { currentLanguage } = useLocalization();
const { t } = useLocalization();
export class UnitListingDetailPresenter extends Presenter<PresentCompanyUnitListingDetail> {
  protected createModel(state: User): PresentCompanyUnitListingDetail {
    const detail = state.companyUnitListingDetail;

    return {
      id: detail?.id ?? '',
      name: detail?.title ?? NO_INFO,
      price: currency(detail?.rentalPrice ?? 0, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      displayAddress: detail?.singleLineAddress ?? NO_INFO,
      address: detail?.singleLineAddress ?? '',
      bedrooms: detail?.beds?.toString() ?? NO_INFO,
      bathrooms: detail?.baths?.toString() ?? NO_INFO,
      area: detail?.unitSizeSqft?.toString() ?? NO_INFO,
      description: detail?.description ?? NO_INFO,
      petPolicy: this.getPetPolicy(detail),
      listed: this.getListed(detail?.listed?.toString() ?? ''),
      images:
        detail?.images?.map((image) => ({
          url: image?.signedUrl ? '/api' + image.signedUrl : '',
          title: image?.fileId ?? '',
        })) ?? [],
      features: this.getFeatures(detail),
      hasFeatures: detail?.amenities?.some((amenity) => amenity.avalability !== UNAVAILABLE) || detail?.petPolicy?.allowed,
      descriptionTranslations: detail?.descriptionTranslations ?? [],
    };
  }

  private getFeatures(detail?: TenantHydratedUnitListingDto) {
    const includedUtilities: Feature = {
      name: INCLUDED_UTILITIES,
      children: [],
    };
    const interiorAmenities: Feature = {
      name: INTERIOR_AMENITIES,
      children: [],
    };
    const outdoorAmenities: Feature = {
      name: OUTDOOR_AMENITIES,
      children: [],
    };
    const communityAmenities: Feature = {
      name: COMMUNITY_AMENITIES,
      children: [],
    };

    const pet: Feature = {
      name: PETS,
      children: [],
    };

    detail?.amenities?.forEach((amenity) => {
      if (amenity.avalability === UNAVAILABLE || !amenity.name) {
        return;
      }
      if (
        [
          GYM,
          SWIMMING_POOL,
          COMMUNITY_CLUBHOUSE,
          PLAYGROUND_OR_DOG_PARK,
          LAUNDRY_FACILITIES,
          PARKING_OR_GARAGE,
          SECURITY_OR_CONTROLLED_ACCESS,
        ].includes(amenity.name)
      ) {
        communityAmenities.children?.push(t(amenity.name));
      }
      if ([PATIO, BBQ_GRILLS_OR_PICNIC_AREA, ROOFTOP_DECK, PARKING_SPACE].includes(amenity.name)) {
        outdoorAmenities.children?.push(t(amenity.name));
      }
      if ([WATER_AND_SEWER, ELECTRICITY, GAS, TRASH, INTERNET_CABLE, HEATING_AIR_CONDITIONING]?.includes(amenity.name)) {
        includedUtilities.children?.push(t(amenity.name));
      }
      if (
        [
          IN_UNIT_WASHER_DRYER,
          DISHWASHER,
          MICROWAVE,
          STAINLESS_STEEL_APPLIANCES,
          HARDWOOD_FLOORING,
          CENTRAL_AC_HEATING,
          WALK_IN_CLOSETS,
          HIGH_SPEED_INTERNET,
          FIREPLACE,
        ].includes(amenity.name)
      ) {
        interiorAmenities.children?.push(t(amenity.name));
      }
    });

    if (detail?.petPolicy && detail.petPolicy.allowed) {
      const size = {
        small: t(SMALL_WEIGHT),
        medium: t(MEDIUM_WEIGHT),
        large: t(LARGE_WEIGHT),
      };

      const petPolicy = this.getPetPolicy(detail);
      pet.children?.push(`${petPolicy.pets ? `${t(ALLOWED)} (${petPolicy.pets})` : t(ALL_ALLOWED)} `);
      pet.children?.push(petPolicy.size ? size[petPolicy.size] : t(NO_LIMIT));
      pet.children?.push(`$${petPolicy.fee} ${t(MONTH_PET_FEES)}`);
      pet.children?.push(`${t(PET_DEPOSIT)}: $${petPolicy.deposit}`);
      petPolicy.description && pet.children?.push(petPolicy.description);
    }
    return [includedUtilities, interiorAmenities, outdoorAmenities, communityAmenities, pet];
  }

  private getListed(data: string) {
    if (!data) {
      return NO_INFO;
    }

    if (currentLanguage() === EN) {
      const date = new Date(data);
      return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    }
    return data;
  }

  private getPetPolicy(detail?: TenantHydratedUnitListingDto) {
    const strArr = [];
    const petPolicy = detail?.petPolicy;

    if (petPolicy?.allowed) {
      for (const key in petPolicy.policies) {
        petPolicy.policies[key as keyof typeof petPolicy.policies] && strArr.push(t(capitalizeFirstLetter(key)));
      }
    }

    return {
      ...petPolicy,
      pets: strArr.join(', '),
    };
  }
}
